<template>
  <el-scrollbar>
    <div class="elv-reports-menu">
      <li v-for="(item, index) in financialsMenuList" :key="index" style="display: flex" @click="onNodeClick(item)">
        <div
          :class="{
            'elv-reports-menu-item__selected': currentSelectedMenu(item.financialId)
          }"
          class="elv-reports-menu-item"
        >
          <span>{{ transformI18n(item.name) }}</span>
        </div>
      </li>

      <div class="elv-reports-menu-right-button">
        <div class="elv-reports-menu-right">
          <!-- class="elv-reports-menu-right__button" -->
          <elv-button
            :disabled="regeneratedRef?.regenerating"
            height="32"
            :type="regeneratedRef?.regenerating ? '' : 'primary'"
            @click="regeneratedRef?.onClickedRegenerate"
          >
            <div v-if="regeneratedRef?.regenerating" class="elv-reports-regenerating">
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
              <span>{{ t('report.regenerating') }}</span>
            </div>
            <template v-else>
              {{ t('report.regenerateReports') }}
              <el-tooltip
                v-if="isOperationReport"
                ref="elvTableTipsRef"
                :show-arrow="false"
                effect="dark"
                placement="top"
                popper-class="elv-reports-regenerate-tips"
                :show-after="500"
              >
                <SvgIcon name="Information-Filled" width="16" height="16" fill="white" class="tips" />
                <template #content>
                  <div class="elv-table-tips-content">{{ t('message.regenerateReportsTips') }}</div>
                </template>
              </el-tooltip>
            </template>
          </elv-button>
        </div>
      </div>
    </div>
    <div class="elv-report-header">
      <div class="elv-report-header-left">
        <h3 class="elv-report-header-left-title">{{ props.title }}</h3>
        <div class="elv-report-header-filter">
          <div v-if="props.type !== 'SIGNIFICANT_HOLDINGS'" class="elv-report-screening-item">
            <span class="elv-report-screening-item__label">{{ t('title.reportPeriod') }}:</span>
            <el-select
              v-model="periodValue"
              placeholder=" "
              popper-class="elv-report-screening-popper"
              class="elv-report-screening-period"
              @change="onChangePeriod"
            >
              <el-option v-for="item in periodOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
          <div v-if="periodValue !== 'year'" class="elv-report-screening-item">
            <span class="elv-report-screening-item__label"
              >{{ props.type !== 'SIGNIFICANT_HOLDINGS' ? t('common.dateRange') : t('common.date') }}:</span
            >
            <ELvDatePicker
              ref="datePickerRef"
              v-model:value="dateValue"
              :type="props.type !== 'SIGNIFICANT_HOLDINGS' && periodValue !== 'year' ? 'range' : 'date'"
              :enable-disabled="props.type !== 'dashboard'"
              :picker="periodValue"
              @change="onRangeChange"
            />
          </div>
        </div>
      </div>
      <div class="elv-report-header-right">
        <elv-export-button
          :params="exportParams"
          :no-permission="
            ['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.report?.view
          "
        />
      </div>
    </div>
    <Regenerated ref="regeneratedRef" :type="props.type" />
  </el-scrollbar>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { find, isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { transformI18n } from '@/i18n/index'
import { financialsMenuList } from '@/config/reports'
import { useEntityStore } from '@/stores/modules/entity'
import Regenerated from '../Project/components/Regenerated.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'
import ELvDatePicker from '@/components/Base/ELvDatePicker/index.vue'

const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  endTime: {
    type: String,
    default: ''
  },
  params: {
    type: Object,
    default: () => ({})
  }
})

const route = useRoute()
const router = useRouter()
const { t, locale } = useI18n()
const entityStore = useEntityStore()
const reportStore = useReportStore()
const emit = defineEmits(['onChangDateTime'])

const selectedMenu = ref('reports-balance-sheet')
const datePickerRef = ref()
const regeneratedRef = ref()
const dateValue = ref()
const periodValue = ref('month')

const periodOptions = ref([
  {
    value: 'date',
    label: t('common.day')
  },
  {
    value: 'month',
    label: t('common.Month')
  },
  {
    value: 'quarter',
    label: t('common.quarter')
  },
  {
    value: 'year',
    label: t('common.year')
  }
])

const currentSelectedMenu = computed(() => (financialId: string) => {
  const result = financialId === selectedMenu.value
  return result
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const balanceSheetTabWidth = computed(() => {
  return locale.value === 'en' ? '122.63px' : '97px'
})
const incomeStatementTabWidth = computed(() => {
  return locale.value === 'en' ? '149.47px' : '71px'
})
const cashFlowStatementTabWidth = computed(() => {
  return locale.value === 'en' ? '166.67px' : '97px'
})
const holdingsTabWidth = computed(() => {
  return locale.value === 'en' ? '158px' : '89px'
})
const restrictionsTabWidth = computed(() => {
  return locale.value === 'en' ? '104px' : '124px'
})
const rollForwardTabWidth = computed(() => {
  return locale.value === 'en' ? '106px' : '124px'
})

const regeneratingBg = computed(() => {
  if (regeneratedRef.value?.regenerating) {
    return '#EEF4FB'
  }
  return '#ffffff'
})

const exportParams = computed(() => {
  const data = {
    type: '',
    extra: props.params
  }
  if (props.params?.period === 'YEAR') {
    delete data.extra.dateRange
  }
  switch (props.type) {
    case 'RESTRICTIONS_OF_CRYPTO_ASSETS':
      data.type = 'REPORT_RESTRICTIONS'
      break
    case 'CRYPTO_ASSETS_ROLL_FORWARD':
      data.type = 'REPORT_ROLL_FORWARD'
      break
    default:
      data.type = `REPORT_${props.type}`
      break
  }
  return data
})

const isShowRegeneratedInfo = computed(() => {
  return regeneratedRef.value?.isShowRegeneratedInfo
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const isOperationReport = computed(() => {
  if (!entityStore.entityDetail?.progress?.postingDoneTime) return false
  return (
    (entityStore.entityDetail?.progress.postingDoneTime && !entityStore.entityDetail?.progress.reportDoneTime) ||
    dayjs(entityStore.entityDetail?.progress.postingDoneTime).isAfter(entityStore.entityDetail?.progress.reportDoneTime)
  )
})

const onRangeChange = (value: any) => {
  if (periodValue.value !== 'date' && value?.length) {
    // 获取所选日期的年份和月份
    const startYear = dayjs(value[0]).year()
    const startMonth = dayjs(value[0]).month()
    const endYear = dayjs(value[1]).year()
    const endMonth = dayjs(value[1]).month()
    // 计算所选月份的第一天和最后一天
    const startDate = dayjs().year(startYear).month(startMonth).date(1).startOf('day')
    const endDate = dayjs().year(endYear).month(endMonth).endOf('month').endOf('day')
    // 设置日期范围
    dateValue.value = [dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]
  }
  // 更改时间后获取数据
  if (props.type !== 'SIGNIFICANT_HOLDINGS') {
    // eslint-disable-next-line no-unused-vars
    const params: any = {
      period: periodValue.value === 'date' ? 'DAY' : periodValue.value.toLocaleUpperCase(),
      dateRange: dateValue.value
    }
    if (dateValue.value) {
      emit('onChangDateTime', params)
    }
  } else {
    // eslint-disable-next-line no-unused-vars
    const params = {
      date: dateValue.value
    }
    emit('onChangDateTime', params)
  }
  if (dateValue.value) {
    const operatingRecord = {
      period: periodValue.value,
      dateValue: dateValue.value
    }
    if (periodValue.value === 'year') {
      delete operatingRecord.dateValue
    }
    reportStore.editReportPeriodDateOperatingRecord(
      entityId.value,
      operatingRecord,
      props.type === 'SIGNIFICANT_HOLDINGS' ? 'HOLDINGS' : 'OTHER'
    )
  }
}

const onInitDateTime = (reset: boolean = false) => {
  const periodDateOperatingRecord = find(reportStore.reportPeriodDateOperateList, { entityId: entityId.value })
  if (props.type === 'SIGNIFICANT_HOLDINGS') {
    periodValue.value = 'date'
    if (!isEmpty(periodDateOperatingRecord) && !isEmpty(periodDateOperatingRecord.holdings) && !reset) {
      dateValue.value = periodDateOperatingRecord.holdings.dateValue
      return
    }
    const dateTime = entityStore.entityDetail?.progress?.reportDoneTime
      ? dayjs
          .tz(entityStore.entityDetail?.progress?.reportDoneTime, entityStore.entityDetail?.timezone ?? 'UTC')
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
      : dayjs()
          .tz(entityStore.entityDetail?.timezone ?? 'UTC')
          .subtract(1, 'day')
          .startOf('day')
    dateValue.value = dayjs(dateTime).format('YYYY-MM-DD')
  } else if (props.type === 'dashboard') {
    periodValue.value = 'date'
    if (props.endTime === '') return
    const dateTime = dayjs(props.endTime).subtract(29, 'day').startOf('day')
    dateValue.value = [dayjs(dateTime).format('YYYY-MM-DD'), props.endTime]
  } else {
    if (!isEmpty(periodDateOperatingRecord) && !isEmpty(periodDateOperatingRecord.other) && !reset) {
      periodValue.value = periodDateOperatingRecord.other.period
      dateValue.value = periodDateOperatingRecord.other.dateValue
      return
    }
    const startDate = dayjs().subtract(6, 'month').date(1).startOf('day')
    const endDate = dayjs().subtract(1, 'month').endOf('month').endOf('day')
    dateValue.value = [dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]
  }
}

const onChangePeriod = () => {
  dateValue.value = undefined
  if (props.type !== 'SIGNIFICANT_HOLDINGS') {
    let startDate
    let endDate
    switch (periodValue.value) {
      case 'date':
        startDate = dayjs().subtract(15, 'day')
        endDate = dayjs().subtract(1, 'day')
        dateValue.value = [dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]
        break
      case 'month':
        onInitDateTime(true)
        break
      default:
        startDate = dayjs().subtract(8, 'quarter').startOf('quarter').date(1).startOf('day')
        endDate = dayjs().subtract(1, 'quarter').endOf('quarter').endOf('day')
        dateValue.value = [dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]
        break
    }
    const params = {
      period: periodValue.value === 'date' ? 'DAY' : periodValue.value.toLocaleUpperCase(),
      dateRange: dateValue.value
    }
    if (periodValue.value === 'year') {
      delete params.dateRange
    }
    emit('onChangDateTime', params)
    if (dateValue.value) {
      const operatingRecord = {
        period: periodValue.value,
        dateValue: dateValue.value
      }
      if (periodValue.value === 'year') {
        delete operatingRecord.dateValue
      }
      reportStore.editReportPeriodDateOperatingRecord(
        entityId.value,
        operatingRecord,
        props.type === 'SIGNIFICANT_HOLDINGS' ? 'HOLDINGS' : 'OTHER'
      )
    }
  }
}

const onNodeClick = (data: any) => {
  switch (data.financialId) {
    case 'disclosure':
      return
    case 'sources':
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.dataSource?.view
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      break
    case 'transactions':
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.transaction?.viewList
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      break
    default:
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.report?.view
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      break
  }
  selectedMenu.value = data.financialId
  router.push({ name: `reports-${data.financialId}` })
}

defineExpose({ isShowRegeneratedInfo, dateValue, periodValue })

watchEffect(() => {
  financialsMenuList.value.forEach((item) => {
    if (`reports-${item.financialId}` === route.name) {
      selectedMenu.value = item.financialId
      periodValue.value = 'month'
      onInitDateTime()
    }
  })
})
</script>
<style lang="scss" scoped>
.elv-reports-menu {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  margin: 20px 0 16px 0px;
  padding-bottom: 16px;
  padding-left: 20px;
  border-bottom: 1px solid #dde1e6;
  position: relative;
  @media screen and (max-width: 820px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  @media screen and (max-width: 390px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  li {
    &:nth-child(1) .elv-reports-menu-item {
      width: v-bind('balanceSheetTabWidth');
    }

    &:nth-child(2) .elv-reports-menu-item {
      width: v-bind('incomeStatementTabWidth');
    }

    &:nth-child(3) .elv-reports-menu-item {
      width: v-bind('cashFlowStatementTabWidth');
    }

    &:nth-child(4) .elv-reports-menu-item {
      background: #dde1e6;
      width: 1px;
      height: 16px;
      margin: 10px 16px 0;
    }

    &:nth-child(5) .elv-reports-menu-item {
      width: v-bind('holdingsTabWidth');
    }

    &:nth-child(6) .elv-reports-menu-item {
      width: v-bind('restrictionsTabWidth');
    }

    &:nth-child(7) .elv-reports-menu-item {
      width: v-bind('rollForwardTabWidth');
    }
  }

  .elv-reports-menu-right-button {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
  }

  .elv-reports-menu-right {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;

    :deep(.el-button.elv-button) {
      padding: 10px 12px;

      .tips {
        margin-left: 6px;
        margin-right: 0px;
      }

      &.is-disabled {
        background: #eef4fb !important;
        border-color: transparent !important;
        box-shadow: none !important;
      }

      .elv-reports-regenerating {
        display: flex;
        align-items: center;

        span {
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      img {
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        animation: loading-rotate 2s linear infinite;
      }
    }
  }

  .elv-reports-menu-right__button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: 152px;
    height: 32px;
    border: 1px solid #dde1e6;
    border-radius: 2px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    cursor: pointer;
    background-color: v-bind('regeneratingBg');

    svg {
      fill: #1e2024;
      margin-right: 8px;
    }

    &:hover {
      color: #1343bf;
      border: 1px solid #7596eb;

      svg {
        fill: #1343bf;
      }
    }

    .elv-reports-regenerating {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 8px;
        animation: loading-rotate 2s linear infinite;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;
      }
    }
  }

  .elv-reports-menu-item {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $elv-theme-text-color;
    box-sizing: border-box;
    background-color: transparent;
    position: relative;

    svg {
      fill: #838d95;
    }

    &::after {
      content: '';
      pointer-events: none;
      bottom: 0px;
      left: 50%;
      position: absolute;
      width: 0%;
      height: 1px;
      background-color: #1343bf;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 400ms;
      transition-property: width, left;
    }
  }

  .elv-reports-menu-item:not(.elv-reports-menu-item__selected):hover {
    span {
      color: #1343bf;
      cursor: pointer;
    }

    svg {
      fill: #1343bf;
    }

    &:hover::after {
      width: calc(100% - 32px);
      left: 16px;
    }
  }

  .elv-reports-menu-item__selected {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 13px;
    color: $elv-color-394048;
    background: $elv-color-EDF0F3 !important;
    border-radius: 23px;
    cursor: default;

    &:hover {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 13px;
      color: $elv-color-394048;
    }
  }
}

.elv-report-header {
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.elv-report-header-left {
  display: flex;
  align-items: center;

  .elv-report-header-left-title {
    font-family: 'Plus Jakarta Sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    margin-left: 22px;
    white-space: nowrap;
  }

  .elv-report-header-filter {
    display: flex;
    align-items: center;
    margin-right: 22px;
    margin-left: 16px;
  }
}

.elv-report-header-right {
  margin-right: 32px;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.el-scrollbar {
  height: auto;
}

.elv-report-screening-item {
  padding-left: 16px;
  margin-left: 16px;
  border-left: 1px solid #dde1e6;
  display: flex;
  align-items: center;

  &:first-child {
    border-left: none;
    margin-left: 0px;
    padding-left: 0px;
  }

  .elv-report-screening-item__label {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: fit-content;
    margin-right: 8px;
    white-space: nowrap;
  }

  .el-select {
    height: 15px;
    width: fit-content;

    .el-input {
      height: 15px;

      .el-input__wrapper {
        border: none !important;
        box-shadow: none !important;
        padding: 0;
      }

      .el-input__inner {
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: fit-content;
      }

      .el-input__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .el-select__wrapper {
      height: 15px;
      min-height: 15px;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      padding-left: 8px;

      .el-select__selection {
        width: fit-content;

        .el-select__placeholder {
          position: relative;
          top: 0;
          transform: none;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
          overflow: visible;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.el-popper.elv-reports-regenerate-tips {
  width: 196px;
  padding: 5px 10px 8px;
  box-sizing: border-box;

  .elv-table-tips-content {
    color: #fff;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 0px;
    word-break: break-word;
  }
}

.elv-report-screening-popper.el-popper.el-select__popper {
  transform: translateY(-6.5px);

  .el-select-dropdown__item {
    height: 32px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.hover,
    &.is-hovering {
      background: #f9fafb;
    }

    &.selected,
    &.is-selected {
      color: #1753eb;
      font-weight: 500;
    }

    &.is-disabled {
      background-color: #f5f7fa !important;
    }
  }
}
</style>
